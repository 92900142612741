@import styles/helpers

.top
    text-align: center
    margin-bottom: 20px
    border-bottom: 1px solid $neutrals6
    +dark
        border-color: $neutrals3

.title
    +dark
        color: #FCFCFD

.note
    text-align: center
    color: $neutrals4
    +body-2

.field 
    padding: 5px
    margin-bottom: 15px
    margin-top: 15px

.btns
    display: flex
    +m
        display: block
        text-align: center
    .button
        +m
            width: 100%
        &:not(:last-child)
            margin-right: 32px
            +m
                margin: 0 0 32px

.clear
    margin-left: 10px
    display: inline-flex
    align-items: center
    +button-1
    color: $neutrals4
    transition: color .2s
    svg
        margin-right: 8px
        fill: $neutrals4
        transition: fill .2s
    &:hover
        color: $blue
        svg
            fill: $blue

.button
    margin-right: 5px

.buttonDisabled
    background: $neutrals4
    &:hover
        background: $neutrals4
