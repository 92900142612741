@import styles/helpers

.top
    text-align: center
    margin-bottom: 20px

.title
    +dark
        color: #FCFCFD

.note
    text-align: center
    color: $neutrals4
    +body-2

.btns
    display: flex
    margin-bottom: 10px
    +m
        display: block

.button
    width: 100%
    margin-bottom: 10px
    cursor: pointer
    +m
        width: 100%
    &:not(:last-child)
        margin-right: 16px
        +m
            margin: 0 0 16px