@import styles/helpers

.title
    +dark
        color: #FCFCFD

.text
    text-align: center
    margin-bottom: 50px
    +body-2
    color: $neutrals4